import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [2];

export const dictionary = {
		"/(std)/(static)": [9,[2]],
		"/(std)/(static)/about-us": [11,[2]],
		"/(std)/(static)/about/[slug]": [~10,[2]],
		"/admin/books": [~32,[7]],
		"/admin/books/[book_id]": [~33,[7]],
		"/admin/pages": [~34,[7]],
		"/admin/pages/[page_id]": [~35,[7]],
		"/admin/users": [~36,[7]],
		"/admin/users/[user_id]": [~37,[7]],
		"/(std)/books": [15,[3]],
		"/(std)/books/[book_id]": [16,[3,4]],
		"/(std)/books/[book_id]/accept/[invite_id]": [17,[3,4]],
		"/(std)/books/[book_id]/bibliography": [18,[3,4]],
		"/(std)/books/[book_id]/chapters/[chapter_id]": [19,[3,4]],
		"/(std)/books/[book_id]/debug/diagnose": [20,[3,4,5]],
		"/(std)/books/[book_id]/debug/mapping": [21,[3,4,5]],
		"/(std)/books/[book_id]/debug/math": [22,[3,4,5]],
		"/(std)/books/[book_id]/debug/math/[eq]": [23,[3,4,5]],
		"/(std)/books/[book_id]/debug/narrow": [24,[3,4,5]],
		"/(std)/books/[book_id]/debug/properties": [25,[3,4,5]],
		"/(std)/books/[book_id]/debug/typst": [26,[3,4,5]],
		"/(std)/books/[book_id]/index": [27,[3,4]],
		"/(std)/books/[book_id]/notes": [28,[3,4]],
		"/(std)/books/[book_id]/title": [29,[3,4]],
		"/(std)/books/[book_id]/toc": [30,[3,4]],
		"/(std)/(static)/contact": [12,[2]],
		"/(std)/settings/link/finished": [31,[6]],
		"/(std)/(static)/tutorial": [~13,[2]],
		"/(std)/(static)/tutorial/[slug]": [~14,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';